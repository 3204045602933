import axios from "axios";
import { Parser } from "xml2js";
import { aulas_inicio } from "../utils/YEARCONSTS";

const getInstanceSponte = () => {
  let user = "";
  const storageData = sessionStorage.getItem("loggedUser");
  if (storageData) {
    user = JSON.parse(storageData);
  }
  const clientCod =
    user.unit === "CAMPINA GRANDE"
      ? "nCodigoCliente=38918"
      : "nCodigoCliente=68262";
  const tokenApi =
    user.unit === "CAMPINA GRANDE" ? "sToken=Mzg5MTg=" : "sToken=o4hE5P0AIVvF";
  return { clientCod, tokenApi };
};

export const api = axios.create({
  baseURL: "https://api.sponteeducacional.net.br/WSAPIEdu.asmx",
});

const parser = new Parser({
  preserveChildrenOrder: true,
  explicitArray: false,
});

export const getAllStudents = async () => {
  const { clientCod, tokenApi } = getInstanceSponte();
  const URL = `/GetMatriculas?${clientCod}&${tokenApi}&sParametrosBusca=SituacaoID=1;AnoLetivo=2024`;
  const { data } = await api.get(URL);
  let response = [];
  parser.parseString(data, (err, result) => {
    response = result.ArrayOfWsMatricula.wsMatricula;
  });
  return response;
};
export const getStudentByName = async (name) => {
  try {
    const { clientCod, tokenApi } = getInstanceSponte();
    const URL = `/GetAlunos?${clientCod}&${tokenApi}&sParametrosBusca=Nome=${name}`;
    const { data } = await api.get(URL);
    let response = [];
    parser.parseString(data, (err, result) => {
      if (Array.isArray(result.ArrayOfWsAluno.wsAluno)) {
        response = result.ArrayOfWsAluno.wsAluno[0].NumeroMatricula;
      } else {
        response = result.ArrayOfWsAluno.wsAluno.NumeroMatricula;
      }
    });
    return response;
  } catch (error) {
    console.log("getStudentByName=> ", error);
  }
};

export const getResponsables = async (idSponte) => {
  const { clientCod, tokenApi } = getInstanceSponte();
  let response = [];

  const URL = `/GetResponsaveis?${clientCod}&${tokenApi}&sParametrosBusca=AlunoID=${idSponte}`;
  const { data } = await api.get(URL);
  parser.parseString(data, (err, result) => {
    response = result.ArrayOfWsResponsavel.wsResponsavel;
  });
  return response;
};

export const getAllClassrooms = async () => {
  const { clientCod, tokenApi } = getInstanceSponte();
  let response = [];
  const URL = `/GetTurmas?${clientCod}&${tokenApi}&sParametrosBusca=situacao=-1;AnoLetivo=2024`;
  const { data } = await api.get(URL);
  parser.parseString(data, (err, result) => {
    response = result.ArrayOfWsTurma.wsTurma;
  });
  return response;
};

export const getStudentsByClassroom = async (TurmaID) => {
  const { clientCod, tokenApi } = getInstanceSponte();
  let response = [];
  const URL = `/GetIntegrantesTurmas?${clientCod}&${tokenApi}&sParametrosBusca=TurmaID=${TurmaID}`;
  const { data } = await api.get(URL);
  parser.parseString(data, (err, result) => {
    response = result.ArrayOfWsIntegrantesTurma.wsIntegrantesTurma.Integrantes;
  });
  return response;
};

export const getSubjects = async (TurmaID) => {
  const { clientCod, tokenApi } = getInstanceSponte();
  let response = [];
  const URL = `/GetMatrizCurricular?${clientCod}&${tokenApi}&sParametrosBusca=TurmaID=${TurmaID}`;
  const { data } = await api.get(URL);
  parser.parseString(data, (err, result) => {
    response = result.ArrayOfWsMatrizCurricular.wsMatrizCurricular.Disciplinas;
  });
  return response;
};

export const getPartialGrades = async (
  TurmaID,
  AlunoID,
  DisciplinaID,
  period
) => {
  const { clientCod, tokenApi } = getInstanceSponte();
  let response = [];
  const params = `nCursoID=0&nTurmaID=${TurmaID}&nAlunoID=${AlunoID}`;
  const filter = `sParametrosBusca=DisciplinaID=${DisciplinaID};Periodo=${period}`;
  const URL = `/GetNotaParcial?${clientCod}&${tokenApi}&${params}&${filter}`;
  const { data } = await api.get(URL);
  parser.parseString(data, (err, result) => {
    response = result;
  });
  return response;
};

export const setPartialGrades = async (grades, avaliacao, nota) => {
  const { clientCod, tokenApi } = getInstanceSponte();
  const params = `nTurmaID=${grades.TurmaID}&nDisciplinaID=${grades.DisciplinaID}&nAlunoID=${grades.AlunoID}&`;
  const filter = `sNomeAvaliacao=${avaliacao}&nNota=${nota}&nPesoAvaliacao=1&nModulo=0&nPeriodo=${grades.period}`;
  const URL = `/SetNotaParcial?${clientCod}&${tokenApi}&${params}&${filter}`;
  const { data } = await api.get(URL);
  return data;
};
export const setFinalGrades = async (grades, nota) => {
  const { clientCod, tokenApi } = getInstanceSponte();
  const params = `nTurmaID=${grades.TurmaID}&nDisciplinaID=${grades.DisciplinaID}&nAlunoID=${grades.AlunoID}&`;
  const filter = `nNota=${nota}&nModulo=1&nPeriodo=${grades.period}`;
  const URL = `/SetNota?${clientCod}&${tokenApi}&${params}&${filter}`;
  const { data } = await api.get(URL);
  return data;
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getStudentsAndResponsablesByClassroom = async (classromsList) => {
  const studentsData = [];

  // Obtem os dados de alunos com delay entre as chamadas
  for (const TurmaID of classromsList) {
    const data = await getStudentsByClassroom(TurmaID);
    studentsData.push(data);
    await delay(500); // Delay de 500ms
  }

  const studentsIds = studentsData.map((item) => {
    if (item && item.hasOwnProperty("Integrantes")) {
      if (Array.isArray(item.Integrantes)) {
        return item.Integrantes.map((res) => res.AlunoID);
      } else {
        return item.Integrantes.AlunoID;
      }
    } else {
      return null;
    }
  });
  const students = [].concat(...studentsIds);

  const responsablesData = [];

  // Obtem os dados dos responsáveis com delay entre as chamadas
  for (const student of students) {
    const data = await getResponsables(student);
    responsablesData.push(data);
    await delay(10000); // Delay de 500ms
  }

  const responsablesIds = responsablesData.map((item) => {
    if (Array.isArray(item)) {
      return item.map((responsable) => responsable.ResponsavelID);
    } else {
      return item.ResponsavelID;
    }
  });
  const responsables = Array.from(new Set([].concat(...responsablesIds)));

  return Array.from(new Set([...students, ...responsables]));
};

export const getGradesBySubject = async (AlunoID, TurmaID, DisciplinaID) => {
  const { clientCod, tokenApi } = await getInstanceSponte();
  let response = {};
  const url = `/GetBoletim?${clientCod}&${tokenApi}&`;
  const parameters = `nAlunoID=${AlunoID}&nTurmaID=${TurmaID}&nDisciplinaID=${DisciplinaID}&nModulo=0`;
  const { data } = await api.get(`${url}${parameters}`);
  parser.parseString(data, (err, result) => {
    response = result.wsBoletim.ListaNotasBoletim.NotasBoletim;
  });
  return response;
};

export const getStudentByID = async (AlunoID) => {
  try {
    const { clientCod, tokenApi } = getInstanceSponte();
    const URL = `/GetAlunos2?${clientCod}&${tokenApi}&sParametrosBusca=AlunoID=${AlunoID}`;
    const { data } = await api.get(URL);
    let response = [];
    parser.parseString(data, (err, result) => {
      if (Array.isArray(result.ArrayOfWsAluno.wsAluno)) {
        response = result.ArrayOfWsAluno.wsAluno[0].InfoBloqueada;
      } else {
        response = result.ArrayOfWsAluno.wsAluno.InfoBloqueada;
      }
    });
    return response;
  } catch (error) {
    console.log("getStudentByName=> ", error);
  }
};

export const getAllPreMatriculas = async () => {
  const { clientCod, tokenApi } = getInstanceSponte();
  const DataInicio =
    clientCod === "nCodigoCliente=38918"
      ? aulas_inicio.patos
      : aulas_inicio.campina;

  const URL = `/GetMatriculas?${clientCod}&${tokenApi}&sParametrosBusca=DataInicio=${DataInicio}`;
  const { data } = await api.get(URL);
  let response = [];
  parser.parseString(data, (err, result) => {
    response = result.ArrayOfWsMatricula.wsMatricula;
  });
  return response;
};

export const setAllContracts = async (ContratoID, SituacaoID) => {
  const { clientCod, tokenApi } = getInstanceSponte();
  const URL = `/UpdateMatricula?${clientCod}&${tokenApi}&nContratoID=${ContratoID}&nContratoAulaLivreID=&nSituacao=${SituacaoID}&nCursoID=&nTipoContratoID=&dDataInicio=&nContratante=&dDataTermino=&dDataMatricula=&nModulo=&sDisciplinasMatricular=&sDisciplinasRemover=&sObservacao=`;
  const { data } = await api.get(URL);
  return data;
};
