const IntegrantePatos = [
  { Nome: "INTEGRAL 2024 INF (MANHÃ)", TurmaID: 1185 },
  { Nome: "INTEGRAL 2024 INF (TARDE)", TurmaID: 1192 },
  { Nome: "INTEGRAL 2024 FUND (MANHÃ)", TurmaID: 1193 },
  { Nome: "INTEGRAL 2024 FUND (TARDE)", TurmaID: 1186 },
];

const IntegranteCampina = [{ Nome: "INTEGRAL 2024", TurmaID: 1114 }];

export const getIntegrateClasses = (unit) => {
  const integranteTurmas =
    unit === "CAMPINA GRANDE" ? IntegranteCampina : IntegrantePatos;
  return integranteTurmas;
};

export const foods_campina = {
  breakfast: {
    foods: [
      { id: 1, name: "Achocolatado" },
      { id: 2, name: "Banana" },
      { id: 3, name: "Biscoito" },
      { id: 4, name: "Bolo" },
      { id: 5, name: "Cereal" },
      { id: 6, name: "Comprou lanche" },
      { id: 7, name: "Cuscuz com ovo" },
      { id: 8, name: "Cuscuz com carne" },
      { id: 9, name: "Iogurte" },
      { id: 10, name: "Lanche de casa" },
      { id: 11, name: "Maçã" },
      { id: 12, name: "Melancia" },
      { id: 13, name: "Misto quente" },
      { id: 14, name: "Laranja" },
      { id: 15, name: "Ovo" },
      { id: 16, name: "Pão assado" },
      { id: 17, name: "Pão com carne" },
      { id: 18, name: "Pão com queijo" },
      { id: 19, name: "Pão com ovo" },
      { id: 20, name: "Pão com presunto" },
      { id: 21, name: "Pão recheado com frango" },
      { id: 22, name: "Panqueca" },
      { id: 23, name: "Queijo" },
      { id: 24, name: "Salada de frutas" },
      { id: 25, name: "Sorda" },
      { id: 26, name: "Suco da fruta" },
      { id: 27, name: "Tapioca" },
      { id: 28, name: "Uva" },
      { id: 29, name: "Vitamina" },
    ],
  },
  lunch: {
    foods: [
      { id: 1, name: "Abacaxi" },
      { id: 2, name: "Almondegas" },
      { id: 3, name: "Arrumadinho" },
      { id: 4, name: "Arroz" },
      { id: 5, name: "Arroz de Leite" },
      { id: 6, name: "Banana" },
      { id: 7, name: "Batata doce" },
      { id: 8, name: "Carne moída" },
      { id: 9, name: "Carne de panela" },
      { id: 10, name: "Carne assada" },
      { id: 11, name: "Carne desfiada" },
      { id: 12, name: "Cuscuz" },
      { id: 13, name: "Feijão" },
      { id: 14, name: "Farofa" },
      { id: 15, name: "Frango assado" },
      { id: 16, name: "Frango de panela" },
      { id: 17, name: "Frango no molho" },
      { id: 18, name: "Frutas variadas" },
      { id: 19, name: "Legumes" },
      { id: 20, name: "Laranja" },
      { id: 21, name: "Macarrão" },
      { id: 22, name: "Melancia" },
      { id: 23, name: "Milho" },
      { id: 24, name: "Omelete" },
      { id: 25, name: "Ovo" },
      { id: 26, name: "Panqueca" },
      { id: 27, name: "Peixe frito" },
      { id: 28, name: "Purê" },
      { id: 29, name: "Salada de folhas" },
      { id: 30, name: "Salada de legumes" },
      { id: 31, name: "Strogonoff de frango" },
      { id: 32, name: "Suco da fruta" },
      { id: 33, name: "Tomate" },
      { id: 34, name: "Torta de legumes" },
      { id: 35, name: "Uva" },
      { id: 36, name: "Verduras" },
    ],
  },
  snack: {
    foods: [
      { id: 1, name: "Achocolatado" },
      { id: 2, name: "Arroz de leite" },
      { id: 3, name: "Banana" },
      { id: 4, name: "Banana com leite ou achocolatado" },
      { id: 5, name: "Biscoito" },
      { id: 6, name: "Bolo" },
      { id: 7, name: "Bolo de caco" },
      { id: 8, name: "Bolo de cenoura" },
      { id: 9, name: "Cereal" },
      { id: 10, name: "Cookies" },
      { id: 11, name: "Comprou lanche" },
      { id: 12, name: "Creme de galinha" },
      { id: 13, name: "Crepioca" },
      { id: 14, name: "Empada de frango" },
      { id: 15, name: "Frutas variadas" },
      { id: 16, name: "Iogurte" },
      { id: 17, name: "Lanche de casa" },
      { id: 18, name: "Laranja" },
      { id: 19, name: "Maça" },
      { id: 20, name: "Melancia" },
      { id: 21, name: "Misto quente" },
      { id: 22, name: "Ovo" },
      { id: 23, name: "Painço" },
      { id: 24, name: "Panqueca" },
      { id: 25, name: "Pão assado" },
      { id: 26, name: "Pão com carne" },
      { id: 27, name: "Pão com presunto" },
      { id: 28, name: "Pão com ovo" },
      { id: 29, name: "Pão com queijo" },
      { id: 30, name: "Pão de queijo" },
      { id: 31, name: "Pastel de frango" },
      { id: 32, name: "Pizza" },
      { id: 33, name: "Pizza de frango" },
      { id: 34, name: "Sorda" },
      { id: 35, name: "Suco da fruta" },
      { id: 36, name: "Tapioca" },
      { id: 37, name: "Tortinha de frango" },
      { id: 38, name: "Uva" },
      { id: 39, name: "Vitamina de banana" },
    ],
  },
};

export const foods_patos = {
  breakfast: {
    foods: [
      { id: 1, name: "Bolo de chocolate" },
      { id: 2, name: "Bolo de cenoura com calda (opcional)" },
      { id: 3, name: "Brownie" },
      { id: 4, name: "Comprou lanche" },
      { id: 5, name: "Chocolate quente" },
      { id: 6, name: "Cupcake" },
      { id: 7, name: "Cuscuz com ovo" },
      { id: 8, name: "Frutas" },
      { id: 9, name: "Lanche de casa" },
      { id: 10, name: "Pão com carne desfiada" },
      { id: 11, name: "Pão de queijo" },
      { id: 12, name: "Suco" },
      { id: 13, name: "Suco ao leite" },
      { id: 14, name: "Tapioca com queijo" },
      { id: 15, name: "Torta de frango" },
      { id: 16, name: "Vitamina de fruta" },
    ],
  },
  lunch: {
    foods: [
      { id: 1, name: "Almôndegas" },
      { id: 2, name: "Arroz com cenoura e repolho" },
      { id: 3, name: "Arroz molinho" },
      { id: 4, name: "Arroz solto" },
      { id: 5, name: "Carne desfiada ao molho branco" },
      { id: 6, name: "Cubinhos de carne cozido" },
      { id: 7, name: "Cubinhos de frango ao molho branco" },
      { id: 8, name: "Farofa" },
      { id: 9, name: "Feijão de Farofa" },
      { id: 10, name: "Feijão Preto" },
      { id: 11, name: "Feijão simples" },
      { id: 12, name: "Fruta" },
      { id: 13, name: "Macarrão" },
      { id: 14, name: "Purê de batata" },
      { id: 15, name: "Risoto de Frango" },
      { id: 16, name: "Rubacão" },
      { id: 17, name: "Salada cozida" },
      { id: 18, name: "Suco" },
      { id: 19, name: "Suco de Fruta" },
      { id: 20, name: "Tirinhas de Carne" },
    ],
  },
  snack: {
    foods: [
      { id: 1, name: "Bolo de laranja com calda" },
      { id: 2, name: "Brownie de batata doce" },
      { id: 3, name: "Cachorro quente" },
      { id: 4, name: "Comprou lanche" },
      { id: 5, name: "Chocolate quente" },
      { id: 6, name: "Fruta" },
      { id: 7, name: "Lanche de casa" },
      { id: 8, name: "Iogurte de Inhame e morango" },
      { id: 9, name: "Panqueca com recheio de frango cremoso" },
      { id: 10, name: "Panqueca de queijo" },
      { id: 11, name: "Pizza saudável" },
      { id: 12, name: "Salada de Fruta" },
      { id: 13, name: "Suco" },
      { id: 14, name: "Suco de fruta (ao leite)" },
      { id: 15, name: "Tapioca recheada (frango e mista)" },
      { id: 16, name: "Torta de cenoura e frango" },
      { id: 17, name: "Vitamina" },
    ],
  },
};

export const aulas_inicio = {
  anoLetivo: 2025,
  campina: "28/01/2025",
  patos: "30/01/2025",
};

export const campinaUsers = [
  {
    id: "1113",
    name: "1º ANO/EF - A (MANHÃ) 2024",
    users: [
      6050, 6501, 6634, 6105, 5128, 5308, 5412, 6630, 6446, 5311, 6048, 5517,
      5803, 5405, 5549, 5636, 4983, 5336, 6550, 5647, 5568, 5444, 6171, 6268,
      6108, 5861, 5344, 8275, 8276, 8900, 8899, 9076, 9077, 8355, 8354, 5934,
      5933, 7277, 7278, 7422, 7423, 5446, 5447, 8817, 8818, 7283, 7284, 8272,
      8273, 7578, 7577, 7954, 7953, 7413, 7414, 7618, 7617, 8633, 9121, 6793,
      6794, 7317, 7316, 8956, 8957, 7745, 7746, 7646, 7647, 6296, 6297, 8450,
      8449, 8553, 3743, 3463, 8357, 8356, 8029, 8030, 7337, 7329,
    ],
  },
  {
    id: "1116",
    name: "1º ANO/EF - B (TARDE) 2024",
    users: [
      5556, 6452, 6453, 6442, 5688, 5791, 6356, 5776, 6067, 6191, 6571, 5697,
      5799, 6259, 7627, 7628, 8829, 8830, 8832, 8831, 8812, 8810, 7800, 7801,
      5716, 5715, 8668, 8667, 5260, 5168, 8302, 8303, 8470, 8471, 8988, 8989,
      7813, 7814, 7798, 7797, 8548, 8547,
    ],
  },
  {
    id: "1202",
    name: "1º ANO/EF - C (TARDE) 2024",
    users: [
      6676, 6696, 5966, 5390, 5372, 6375, 5274, 6614, 5589, 6213, 6411, 5233,
      6045, 6448, 6651, 6681, 1885, 1884, 9192, 9191, 8157, 8158, 7393, 7392,
      7034, 7031, 8707, 8708, 7231, 7230, 9052, 9053, 9118, 4649, 8495, 8494,
      8772, 8771, 7169, 7170, 8268, 8267, 8266, 8821, 8822, 9099, 9100, 9168,
      9169,
    ],
  },
  {
    id: "1153",
    name: "1º SERIÉ/EM - A (MANHÃ) 2024",
    users: [
      5188, 3661, 4389, 1061, 5629, 5628, 1973, 5911, 4656, 4075, 4652, 3504,
      4792, 3591, 3250, 1756, 4127, 1175, 3656, 686, 1710, 4733, 1871, 1224,
      1776, 1219, 3448, 7109, 7110, 4759, 4761, 5908, 5907, 71, 70, 7720, 7721,
      2891, 2892, 8093, 8094, 6296, 6297, 5369, 5370, 6294, 6295, 4511, 4512,
      5969, 5934, 5968, 9118, 4649, 4079, 4078, 2209, 2208, 5463, 5464, 2477,
      2476, 4750, 2803, 2804, 177, 2266, 6410, 6411, 2575, 2576, 1696, 1695,
      2238, 2239, 758, 759, 4424, 4423, 4923,
    ],
  },
  {
    id: "1197",
    name: "1º SERIÉ/EM - B (MANHÃ) 2024",
    users: [
      6389, 6465, 6072, 6627, 6532, 6658, 6523, 6444, 6382, 6578, 6507, 6391,
      6624, 6595, 1241, 6428, 6556, 6625, 6691, 6542, 6471, 1707, 6671, 4657,
      6569, 6466, 6584, 8739, 8740, 8844, 8843, 8308, 8310, 9067, 9068, 8941,
      8939, 9112, 9111, 8929, 8928, 6892, 8816, 8722, 8721, 8998, 8999, 8907,
      8908, 8742, 8741, 9065, 9064, 9022, 9023, 1719, 1718, 8791, 8792, 8966,
      8965, 4849, 8492, 9183, 9182, 8951, 8952, 8852, 7165, 652, 6041, 651,
      9144, 9143, 6298, 6301, 8985, 8984, 9007, 9006,
    ],
  },
  {
    id: "1118",
    name: "2º ANO/EF - A (MANHÃ) 2024",
    users: [
      4941, 5547, 5248, 5890, 5839, 5217, 5840, 6074, 5034, 5315, 6423, 5464,
      6531, 5928, 4959, 5380, 5897, 4989, 6398, 6497, 5798, 6125, 2553, 2552,
      7613, 7614, 4779, 4778, 8068, 8069, 7994, 7995, 979, 980, 7997, 7996,
      8308, 8310, 7250, 6871, 721, 7292, 8786, 8785, 7534, 7492, 8940, 8938,
      8114, 8113, 6768, 4424, 7380, 7381, 5459, 5460, 6803, 6804, 8750, 8751,
      8890, 8889, 7946, 7947, 8385, 8384,
    ],
  },
  {
    id: "1119",
    name: "2º ANO/EF - B (TARDE) 2024",
    users: [
      5089, 4643, 5197, 6370, 6513, 4896, 4965, 6582, 4936, 5825, 5850, 5795,
      5602, 5018, 5600, 6372, 5011, 5015, 5009, 6355, 6964, 6963, 5511, 5512,
      5513, 6090, 6091, 8694, 8695, 8915, 8914, 6665, 6664, 6666, 6762, 6763,
      8317, 8318, 5552, 5546, 7979, 7978, 8011, 8012, 4912, 4913, 7688, 7689,
      6845, 6846, 7686, 7687, 8696, 8697, 5333, 5332, 6842, 6841, 3520, 3580,
      8668, 8667,
    ],
  },
  {
    id: "1120",
    name: "2º ANO/EF - C (TARDE) 2024",
    users: [
      6613, 6435, 5658, 6080, 5527, 5950, 4977, 6124, 5401, 5841, 6071, 5571,
      5437, 5327, 6348, 9049, 9050, 8803, 8802, 1978, 7761, 8321, 8322, 7587,
      7586, 8138, 8139, 6783, 6784, 8382, 8381, 8383, 7407, 7408, 7999, 7998,
      8309, 8311, 7650, 7651, 7456, 7455, 7305, 9167, 7306, 8659, 8658,
    ],
  },
  {
    id: "1154",
    name: "2º SERIÉ/EM - A (MANHÃ) 2024",
    users: [
      5013, 4124, 4126, 3843, 2399, 6209, 2098, 4465, 5071, 4321, 4650, 2124,
      6055, 6574, 4974, 4299, 3095, 4491, 3446, 4259, 4307, 4050, 5630, 5631,
      3703, 3063, 2267, 307, 5625, 486, 5002, 4879, 5194, 1246, 2006, 6056,
      1257, 6838, 6837, 5463, 5464, 5007, 5009, 5008, 3520, 3580, 8491, 8492,
      3062, 8713, 6026, 6027, 6932, 6933, 2205, 2204, 8706, 9123, 483, 433,
      8287, 8286, 8982, 8981, 6777, 6776, 7660, 9161, 3879, 3880, 1268, 6063,
      4421, 4420, 2291, 2289, 5759, 5758, 9232, 5336, 5335, 7720, 7721, 4822,
      4823, 3833, 3832, 3709, 3398, 248, 249, 7718, 6554, 6555, 2863, 2865,
      6825, 6824, 8158, 7135, 7117, 7119, 7118, 720, 721, 3263, 3264, 8289,
      8288, 1739, 1740,
    ],
  },
  {
    id: "1121",
    name: "3º ANO/EF - A (MANHÃ) 2024",
    users: [
      4514, 6502, 6107, 6520, 4521, 4594, 6476, 5837, 5755, 6554, 5299, 4935,
      6141, 6396, 4788, 5934, 4619, 6575, 5366, 5004, 6388, 4626, 4906, 6530,
      6529, 6100, 6099, 8900, 8899, 8357, 8356, 6298, 6301, 5035, 5034, 5036,
      6224, 6225, 8857, 8858, 7990, 7991, 7896, 7895, 8963, 8962, 7262, 7263,
      6730, 1661, 4511, 4512, 8747, 8746, 4665, 4666, 8124, 8123, 6257, 6256,
      8994, 8993, 7362, 7363, 6733, 6734, 8738, 8737, 3799, 3205, 6680, 6679,
      8936, 8937, 8934, 8935,
    ],
  },
  {
    id: "1122",
    name: "3º ANO/EF - B (TARDE) 2024",
    users: [
      6376, 4544, 5800, 4636, 6559, 6243, 5021, 5863, 5612, 5381, 5682, 5550,
      6091, 4813, 5761, 5396, 4973, 5832, 6109, 8709, 8710, 6135, 6134, 7669,
      7670, 7948, 6278, 6279, 6588, 8527, 8526, 6849, 6848, 8034, 8035, 7702,
      7701, 7382, 7383, 7791, 7792, 7620, 7619, 8337, 8336, 9157, 3090, 7906,
      7905, 5939, 8634, 5940, 6777, 6776, 7985, 7984, 8358, 6074,
    ],
  },
  {
    id: "1123",
    name: "3º ANO/EF - C (TARDE) 2024",
    users: [
      6537, 5140, 5965, 4840, 6216, 5151, 5416, 6086, 6511, 5564, 4613, 6223,
      6088, 5177, 5491, 6087, 5641, 4585, 8944, 8945, 7034, 7031, 8152, 8156,
      8155, 5867, 5866, 8500, 8501, 7048, 7050, 7430, 7429, 8331, 8332, 8913,
      7643, 7642, 2863, 9346, 8508, 8509, 1470, 1471, 7539, 7538, 7736, 7735,
      6218, 9130,
    ],
  },
  {
    id: "1155",
    name: "3º SERIÉ/EM - A (MANHÃ) 2024",
    users: [
      167, 4655, 4816, 5887, 6493, 1385, 706, 193, 4441, 5963, 4734, 6011, 4122,
      6303, 5447, 176, 4267, 2123, 200, 623, 5528, 5788, 1215, 3228, 5168, 6234,
      3768, 878, 898, 879, 6296, 6297, 6554, 6555, 8063, 8062, 8887, 2781, 2780,
      979, 980, 3083, 3082, 5996, 5997, 8153, 8154, 5291, 5292, 8220, 8221,
      5459, 5460, 8594, 8593, 7469, 7470, 692, 693, 5690, 5694, 3258, 3257,
      3100, 3101, 924, 925, 7588, 3201, 7937, 7936, 333, 903, 866, 30, 7076,
      7078, 8895, 8516, 8517, 4901, 4902,
    ],
  },
  {
    id: "1124",
    name: "4º ANO/EF - A (MANHÃ) 2024",
    users: [
      4340, 4316, 6657, 6539, 4017, 5521, 5495, 6566, 4780, 4289, 4937, 4772,
      4393, 4272, 4238, 5398, 4114, 4113, 5772, 5771, 9110, 9109, 8948, 8947,
      5282, 5281, 6454, 9160, 7546, 7545, 8982, 8981, 6498, 6497, 4388, 4389,
      2296, 2295, 1988, 6486, 5915, 5914, 5699, 5698, 5648, 5649, 6554, 6555,
    ],
  },
  {
    id: "1125",
    name: "4º ANO/EF - B (TARDE) 2024",
    users: [
      4539, 4320, 5855, 6669, 6462, 4174, 4164, 6488, 4658, 4606, 6405, 4388,
      5605, 5209, 4463, 4394, 5687, 5542, 4929, 5986, 5426, 4152, 4506, 1719,
      1718, 2873, 3304, 8022, 8021, 9148, 9149, 8838, 8839, 5552, 5546, 5532,
      5533, 8879, 8878, 6299, 6300, 6244, 9238, 8764, 8763, 4589, 4466, 7692,
      7693, 7136, 7345, 7137, 7162, 9119, 6023, 1641, 5916, 7798, 7797, 7608,
      2550, 3023, 3024, 8183, 8182, 7441, 7443, 5511, 5512, 5513, 6090, 6091,
    ],
  },
  {
    id: "1126",
    name: "4º ANO/EF - C (MANHÃ) 2024",
    users: [
      6445, 5251, 6429, 5187, 3830, 6447, 5851, 4954, 4028, 4647, 5944, 4270,
      6306, 6481, 6336, 6427, 6479, 4378, 4276, 5511, 4648, 6892, 8816, 7193,
      7194, 8793, 8794, 7108, 7107, 4988, 4987, 8504, 8819, 8820, 8013, 8014,
      6752, 6753, 5299, 5300, 2891, 2892, 8133, 8132, 3799, 3205, 9137, 8599,
      8867, 9162, 8647, 8648, 2307, 8790, 8863, 8864, 3104, 3103, 5707, 5708,
      7307, 7308, 4058, 4087,
    ],
  },
  {
    id: "1127",
    name: "5º ANO/EF - A (MANHÃ) 2024",
    users: [
      5227, 4444, 5913, 6577, 3857, 5404, 6524, 5591, 6645, 4972, 3601, 5615,
      4806, 4744, 4443, 5155, 4145, 6170, 4469, 4426, 3602, 5783, 7160, 7161,
      3258, 3257, 8093, 8094, 9128, 8997, 5035, 5034, 5036, 7410, 7409, 8929,
      8928, 519, 7674, 9091, 9090, 6774, 6773, 6775, 4, 1050, 7710, 7709, 2575,
      2576, 5710, 5709, 7058, 7059, 5500, 5499, 8450, 8449, 6026, 6027, 5969,
      5934, 5968, 4668, 4670, 7931, 7930,
    ],
  },
  {
    id: "1128",
    name: "5º ANO/EF - B (TARDE) 2024",
    users: [
      5175, 5020, 5587, 5842, 6439, 5601, 5523, 5931, 6594, 4978, 4280, 6231,
      6408, 6021, 4433, 5207, 5962, 6349, 4240, 7090, 7089, 6849, 6848, 7669,
      7670, 8000, 8001, 7998, 8807, 8806, 7688, 7689, 7587, 7586, 8118, 8119,
      9024, 9021, 6785, 6786, 5716, 5715, 8512, 8513, 8765, 8766, 8235, 8234,
      5985, 5986, 7136, 7345, 7137, 8152, 8156, 8659, 8658, 5650, 5651,
    ],
  },
  {
    id: "1129",
    name: "5º ANO/EF - C (TARDE) 2024",
    users: [],
  },
  {
    id: "1146",
    name: "6º ANO/EF - A (MANHÃ) 2024",
    users: [
      3867, 3822, 3585, 4308, 4676, 3745, 3589, 3600, 3485, 4500, 3542, 3887,
      3990, 5797, 4375, 4183, 5603, 3528, 4899, 4938, 4939, 5632, 4061, 7229,
      5054, 5055, 4968, 4969, 4639, 3419, 5759, 5758, 9232, 6328, 6329, 4880,
      4878, 4647, 9066, 4648, 4665, 4666, 4483, 6079, 8620, 6078, 2682, 2681,
      4775, 4559, 4560, 5241, 5242, 7946, 7947, 8633, 1022, 1021, 7691, 7690,
      4587, 4550, 6670, 6671, 6733, 6734, 7724, 7726, 7723, 678, 677,
    ],
  },
  {
    id: "1147",
    name: "6º ANO/EF - B (MANHÃ) 2024",
    users: [
      4403, 3606, 6433, 6581, 3618, 6397, 6618, 5901, 5432, 6473, 6547, 6449,
      3580, 4041, 4757, 6572, 3514, 5349, 4077, 5836, 3621, 6583, 3541, 5934,
      5933, 4675, 4674, 8800, 8801, 8799, 9003, 9004, 878, 898, 879, 8749, 8748,
      9056, 9057, 8084, 8085, 7452, 7451, 8852, 8954, 8955, 8825, 8823, 4149,
      4148, 4133, 5319, 5318, 6452, 6451, 8990, 8991, 6044, 4527, 4528, 7340,
      4798, 5373, 5374, 7990, 7991, 354, 2304, 9007, 9006, 4569, 4568,
    ],
  },
  {
    id: "1196",
    name: "6º ANO/EF - C (TARDE) 2024",
    users: [
      6616, 5953, 6456, 6678, 6593, 6078, 5865, 4006, 6425, 4239, 6211, 5686,
      6451, 6184, 4128, 9055, 8142, 8141, 8832, 8831, 9159, 9017, 9018, 8317,
      8318, 8034, 8035, 5260, 5168, 8788, 8789, 5650, 5651, 8495, 8494, 7798,
      7797, 8828, 8827, 8462, 8461, 5465, 5466,
    ],
  },
  {
    id: "1148",
    name: "7º ANO/EF - A (MANHÃ) 2024",
    users: [
      3385, 6253, 4189, 5794, 3294, 3622, 3267, 3424, 5167, 3064, 2107, 3534,
      6540, 3997, 2178, 3099, 3170, 3439, 3716, 5328, 4367, 5156, 6459, 3673,
      6516, 4834, 4440, 4110, 4031, 4322, 400, 8542, 5274, 3465, 8631, 3579,
      7943, 7942, 3482, 3049, 3048, 4114, 4113, 4388, 4389, 7076, 7078, 3834,
      4209, 3212, 3221, 4559, 4560, 8949, 8953, 660, 659, 2274, 2275, 3879,
      3880, 3979, 3980, 3258, 3257, 4843, 4842, 7307, 7308, 5867, 5866, 7058,
      7059, 4822, 4823, 4779, 4778, 8919, 8918, 6580, 6581, 5996, 5997, 3054,
      2769, 5308, 8698, 5310,
    ],
  },
  {
    id: "1149",
    name: "7º ANO/EF - B (TARDE) 2024",
    users: [
      6684, 3272, 6112, 5945, 6535, 6144, 6103, 5985, 3474, 6628, 3587, 2031,
      6470, 4038, 9173, 4128, 4127, 8699, 8363, 8364, 8134, 8133, 8132, 8944,
      8945, 8408, 8409, 8351, 8352, 8183, 8182, 4589, 4466, 9069, 9080, 4643,
      4642, 3177, 3176, 8850, 8851, 5316, 5315,
    ],
  },
  {
    id: "1150",
    name: "8º ANO/EF - A (MANHÃ) 2024",
    users: [
      6483, 5575, 5670, 6229, 6320, 3263, 505, 3785, 5557, 4712, 2334, 3662,
      2355, 2052, 4058, 6106, 4928, 201, 6548, 3653, 4425, 3340, 3235, 3617,
      4593, 5543, 6458, 2233, 5554, 115, 279, 3860, 3637, 3486, 2025, 6150,
      8868, 8869, 7656, 7655, 7775, 7776, 7271, 7270, 8618, 8617, 4104, 4103,
      1405, 1406, 4921, 4922, 7561, 7629, 7630, 6383, 6384, 1567, 1568, 4758,
      4760, 3534, 3482, 3215, 3222, 5345, 5344, 8355, 8354, 6720, 6719, 3104,
      3103, 8954, 8955, 3666, 3291, 5969, 5934, 5968, 4246, 4245, 4087, 4691,
      4692, 9126, 5332, 7609, 7610, 4822, 4823, 3366, 3706, 7623, 3468, 2249,
      2250, 2937, 2936, 5039, 5040, 4723, 4724, 4486, 4485, 3799, 3205, 8418,
      8417,
    ],
  },
  {
    id: "1151",
    name: "8º ANO/EF - B (TARDE) 2024",
    users: [
      3236, 6605, 2169, 3475, 3774, 6137, 6650, 1947, 6217, 5588, 4061, 4060,
      1201, 1200, 2873, 3304, 4467, 6615, 4912, 4913, 8402, 8403, 8358, 9113,
      3271, 3270, 8500, 8501, 7671, 7672,
    ],
  },
  {
    id: "1152",
    name: "9º ANO/EF - A (MANHÃ) 2024",
    users: [
      2318, 5048, 223, 5520, 5805, 6482, 5525, 1640, 2142, 3152, 1692, 5573,
      6533, 5831, 1783, 184, 3525, 637, 3091, 6437, 4157, 2108, 5616, 2102,
      2061, 5403, 3051, 3278, 1862, 5784, 2329, 5883, 3125, 190, 5519, 5900,
      5144, 2312, 394, 3554, 3455, 6895, 6896, 1398, 1397, 7580, 7581, 7691,
      7690, 8867, 9162, 9243, 2739, 7585, 2846, 1844, 1845, 368, 365, 4056,
      4057, 6539, 2209, 2245, 2244, 7653, 7654, 8940, 8938, 7985, 7984, 330,
      156, 2296, 2295, 4955, 4546, 3185, 4269, 3875, 3874, 8805, 8804, 8158,
      5521, 3214, 3221, 3213, 7710, 7709, 3237, 3238, 3049, 3048, 7410, 7409,
      3822, 3823, 4149, 4148, 4133, 2553, 2552, 7933, 7932, 3743, 3463, 8056,
      8055, 3922, 3921, 3023, 3024, 7582, 7579, 8082, 8083, 7040, 7041, 3448,
      3447, 6139, 4768, 354, 2304,
    ],
  },
  {
    id: "1135",
    name: "INFANTIL I - A (MANHÃ) 2024",
    users: [
      6586, 6519, 6403, 6596, 6710, 6489, 6675, 6374, 6379, 6383, 6711, 6377,
      6585, 6495, 9012, 9011, 8923, 8922, 8760, 8761, 9025, 9210, 9209, 7317,
      7316, 9153, 9154, 5699, 5698, 8717, 8716, 8723, 8724, 7646, 7647, 8712,
      8711, 9008, 9009, 8890, 8889,
    ],
  },
  {
    id: "1134",
    name: "INFANTIL I - B (TARDE) 2024",
    users: [
      6588, 6407, 6695, 6568, 6653, 6387, 6672, 6698, 6713, 6737, 9014, 9013,
      8527, 8526, 9189, 9190, 8191, 8192, 9104, 9103, 8735, 8734, 9146, 9147,
      8462, 8461, 9212, 9213, 8347, 8346,
    ],
  },
  {
    id: "1137",
    name: "INFANTIL II - A (MANHÃ) 2024",
    users: [
      6159, 6400, 6304, 6409, 6570, 6313, 6205, 6503, 6693, 6070, 6484, 6659,
      6339, 6292, 8432, 8755, 8754, 8596, 8595, 8768, 8767, 8986, 8987, 8608,
      8607, 8488, 8487, 8901, 9185, 9186, 3237, 3238, 8871, 8870, 8753, 8752,
      4587, 4550, 8584, 8583,
    ],
  },
  {
    id: "1136",
    name: "INFANTIL II - B (TARDE) 2024",
    users: [
      6546, 6286, 6597, 6633, 6102, 6490, 6655, 6153, 6438, 6410, 6647, 6346,
      6606, 6463, 6785, 6786, 7706, 7705, 9026, 9075, 9074, 7620, 7619, 8882,
      8881, 9106, 9107, 7456, 7455, 7325, 7326, 8772, 8771, 7048, 7050, 8653,
      8654, 5867, 5866, 8838, 8839,
    ],
  },
  {
    id: "1133",
    name: "INFANTIL III - A (MANHÃ) 2024",
    users: [
      6197, 6221, 6612, 6682, 6142, 6353, 5990, 5977, 6500, 6522, 6434, 6193,
      6708, 8476, 8477, 8505, 8506, 9048, 9051, 9171, 9170, 6224, 6225, 8663,
      8664, 8187, 8188, 8084, 8085, 8896, 8897, 8927, 8926, 8800, 8801, 8472,
      8473, 9205, 9206,
    ],
  },
  {
    id: "1132",
    name: "INFANTIL III - B (MANHÃ) 2024",
    users: [
      6081, 6338, 6129, 6266, 5732, 6305, 6064, 6361, 6214, 6003, 6267, 6012,
      6002, 8323, 8324, 8649, 8650, 8391, 8392, 8552, 8553, 8554, 7871, 7870,
      8597, 8598, 8301, 8300, 8677, 6925, 8676, 8497, 8496, 8210, 4961, 3743,
      3463, 6793, 6794,
    ],
  },
  {
    id: "1130",
    name: "INFANTIL III - C (TARDE) 2024",
    users: [
      5956, 6155, 6543, 6190, 6687, 6402, 6178, 6643, 6194, 6365, 8145, 4568,
      8425, 8424, 8420, 8419, 8470, 8471, 9178, 9177, 8759, 8758, 8455, 8456,
      9089, 9088, 8474, 8475, 8686, 8685,
    ],
  },
  {
    id: "1131",
    name: "INFANTIL III - D (TARDE) 2024",
    users: [
      6060, 6173, 6426, 6590, 6238, 6363, 6515, 6291, 5976, 6440, 6415, 5773,
      6323, 6239, 8298, 8297, 7671, 7672, 8788, 8789, 9017, 9018, 7231, 7230,
      8681, 8682, 8680, 8919, 8918, 8702, 8704, 8705, 8703, 8173, 8172, 8808,
      8809, 8775, 8776, 3083, 3082, 8626, 8627, 4005, 9245,
    ],
  },
  {
    id: "1140",
    name: "INFANTIL IV - A (MANHÃ) 2024",
    users: [
      6668, 5995, 5593, 6007, 5580, 6558, 6450, 6246, 9140, 9139, 8198, 8197,
      6932, 7677, 8214, 8215, 3831, 5707, 5708, 8968, 8969, 8824, 8826, 8532,
      8531,
    ],
  },
  {
    id: "1139",
    name: "INFANTIL IV - B (TARDE) 2024",
    users: [
      6668, 5995, 5593, 6007, 5580, 6558, 6450, 6246, 9140, 9139, 8198, 8197,
      6932, 7677, 8214, 8215, 3831, 5707, 5708, 8968, 8969, 8824, 8826, 8532,
      8531,
    ],
  },
  {
    id: "1139",
    name: "INFANTIL IV - B (TARDE) 2024",
    users: [
      6455, 5709, 6201, 6475, 5998, 5806, 6013, 5790, 5992, 5810, 6110, 5576,
      6599, 6512, 8834, 5915, 5914, 8480, 8479, 8853, 8855, 8203, 8204, 7958,
      7957, 8223, 8222, 7940, 7941, 8191, 8192, 7962, 7963, 8360, 8359, 7656,
      7655, 9029, 9030, 8915, 8914,
    ],
  },
  {
    id: "1200",
    name: "INFANTIL IV - C (TARDE) 2024",
    users: [
      6061, 6694, 5923, 5610, 6250, 6182, 6262, 6183, 6062, 5613, 8056, 8055,
      9187, 9188, 8109, 8108, 7698, 7700, 8092, 5039, 5040, 8462, 8461, 8072,
      8071, 8070, 7706, 7705,
    ],
  },
  {
    id: "1203",
    name: "INFANTIL IV - D (MANHÃ) 2024",
    users: [
      6545, 5646, 5604, 5608, 6337, 6514, 6422, 5973, 6714, 6838, 6837, 7745,
      7746, 3834, 4209, 5299, 5300, 8647, 8648, 8917, 8916, 8786, 8785, 8169,
      8168, 9215, 9214,
    ],
  },
  {
    id: "1142",
    name: "INFANTIL V - A (MANHÃ) 2024",
    users: [
      6298, 5729, 6242, 5739, 6192, 5867, 6175, 6399, 5715, 5397, 6610, 5917,
      5286, 5946, 6509, 6716, 8586, 8585, 7864, 7863, 8524, 8525, 7337, 7329,
      8472, 8473, 8037, 8038, 8452, 8453, 8753, 8752, 2058, 2057, 7404, 7403,
      9045, 9047, 9044, 8100, 8099, 7247, 7246, 8135, 264, 5831, 5830, 9217,
      9216,
    ],
  },
  {
    id: "1144",
    name: "INFANTIL V - B (MANHÃ) 2024",
    users: [
      6692, 6225, 6443, 6607, 5722, 5290, 5912, 5692, 6660, 5935, 5581, 5535,
      5848, 6717, 9184, 9183, 8510, 8511, 8814, 8815, 9041, 9040, 7851, 7852,
      6100, 6099, 8093, 8094, 7807, 7806, 9116, 9115, 8124, 8123, 7662, 7661,
      7597, 7598, 8008, 8007, 9218, 9219,
    ],
  },
  {
    id: "1145",
    name: "INFANTIL V - C (TARDE) 2024",
    users: [
      5802, 6436, 5906, 6079, 6485, 5879, 6022, 6508, 6156, 5829, 5402, 5731,
      5808, 6368, 6083, 6244, 7951, 7952, 8803, 8802, 878, 879, 8319, 8320,
      8873, 8872, 7193, 7194, 8237, 8236, 8910, 8909, 8427, 8426, 7983, 7982,
      6135, 6134, 7867, 7868, 7960, 7961, 8691, 8690, 8327, 8528, 8529,
    ],
  },
  {
    id: "1199",
    name: "INFANTIL V - D (TARDE) 2024",
    users: [
      5606, 5597, 5590, 6066, 5674, 5413, 6215, 5892, 5884, 5745, 5341, 6200,
      5698, 6199, 5522, 5792, 5551, 6300, 4079, 4078, 5316, 5315, 1646, 7673,
      8302, 8303, 7781, 7782, 7424, 7425, 1410, 8499, 8498, 8072, 8071, 8070,
      8056, 8055, 7885, 7884, 7325, 7326, 8482, 8481, 7813, 7814, 8480, 8479,
      6580, 2936, 4061, 4060, 4675, 4674, 8590, 8589,
    ],
  },
  {
    id: "1201",
    name: "INFANTIL V - E (TARDE) 2024",
    users: [
      [
        6683, 6553, 5894, 6644, 5999, 6151, 6034, 6414, 6371, 5617, 6077, 6709,
        9172, 3509, 8961, 8964, 8960, 8076, 8075, 9089, 9088, 8205, 8206, 8420,
        8419, 8252, 8251, 8775, 8776, 8694, 8695, 6044, 4527, 4528, 8316, 8315,
        9208, 9207,
      ],
    ],
  },
];

export const patosUsers = [
  {
    id: "1156",
    name: "1º ANO/EF - B 2024 (MANHÃ)",
    users: [
      6635, 6404, 6180, 5567, 6111, 6401, 5785, 6604, 5675, 6525, 6307, 6707,
      6534, 9078, 9079, 7945, 8762, 8459, 8460, 7633, 7634, 8362, 8361, 8756,
      8757, 7934, 9037, 9038, 7784, 7783, 8930, 8600, 9203, 8942, 8943,
    ],
  },
  {
    id: "1157",
    name: "1º ANO/EF - C 2024 (TARDE)",
    users: [
      6233, 5843, 5294, 5320, 6703, 6123, 5276, 5659, 5585, 5221, 5316, 8514,
      8515, 9134, 8002, 7259, 7258, 7298, 7297, 9200, 9199, 8379, 8380, 7235,
      7234, 7762, 7763, 7668, 7667, 7149, 7148, 7294, 7293,
    ],
  },
  {
    id: "1191",
    name: "1º ANO/EF - D 2024 (TARDE)",
    users: [
      5960, 5903, 5922, 6632, 5335, 6385, 5607, 6162, 5287, 5695, 6392, 6325,
      8148, 8149, 8088, 8107, 6521, 9073, 7314, 7315, 8727, 8731, 7695, 7694,
      8435, 8434, 7180, 7181, 7810, 8743, 9136, 8630,
    ],
  },
  {
    id: "1190",
    name: "1º ANO/EF - A 2024 (MANHÃ)",
    users: [
      5293, 5743, 5516, 5876, 5417, 5369, 5389, 5667, 5306, 5231, 5429, 5277,
      5202, 5232, 5661, 7256, 7255, 7866, 7865, 7575, 7576, 8049, 8048, 7432,
      7431, 6258, 6259, 9327, 8439, 6792, 7772, 6809, 6811, 7168, 7167, 6937,
      6934, 4762, 4465, 7128, 7129, 6626, 6625, 7764, 7765,
    ],
  },
  {
    id: "1169",
    name: "1º SÉRIE/EM 2024 (MANHÃ)",
    users: [
      5310, 5877, 4566, 5833, 5462, 4723, 6494, 4982, 6005, 5830, 6541, 6100,
      5885, 5970, 6093, 6152, 6431, 4775, 6600, 6491, 5355, 5475, 7281, 7282,
      8050, 6175, 6174, 7986, 7987, 7489, 6398, 6399, 8888, 9327, 6792, 8212,
      7981, 8422, 8950, 8348, 8349, 8058, 8057, 8162, 8163, 8339, 8421, 8798,
      8797, 9127, 6490, 9031, 8883, 8884, 7346, 7347, 7508, 7509,
    ],
  },
  {
    id: "1158",
    name: "2º ANO/EF 2024 (MANHÃ)",
    users: [
      5362, 5718, 5008, 6138, 5668, 5690, 5870, 4997, 5971, 5059, 5481, 5326,
      5644, 5193, 5141, 6416, 5029, 6441, 5136, 6589, 5982, 5853, 6204, 6176,
      5254, 6175, 6174, 7845, 7846, 6832, 6831, 8405, 8404, 7773, 7774, 7805,
      7804, 9204, 8042, 6233, 6234, 8165, 7197, 8164, 6908, 6909, 8238, 7516,
      7515, 6443, 6442, 9114, 7740, 7739, 7120, 6744, 7032, 7033, 8777, 6863,
      6862, 8813, 8811, 7026, 7027, 9016, 9015, 8178, 8018, 8017, 8485, 8486,
      8454, 7198,
    ],
  },
  {
    id: "1159",
    name: "2º ANO/EF 2024 (TARDE)",
    users: [
      6621, 5672, 6202, 5283, 6664, 5766, 5079, 5033, 6187, 5696, 5937, 5149,
      6237, 5452, 9060, 7778, 8483, 7244, 7245, 9133, 7909, 6944, 6945, 6868,
      6869, 8467, 8468, 7811, 7812, 8778, 8125, 7047, 7046, 8520, 8521, 7478,
      7477,
    ],
  },
  {
    id: "1170",
    name: "2º SÉRIE/EM 2024 (MANHÃ)",
    users: [
      6663, 6308, 4667, 5143, 5146, 6122, 4560, 4637, 5313, 6685, 5042, 6169,
      6198, 5406, 5124, 5393, 5812, 9129, 8600, 6315, 6314, 7038, 7037, 7043,
      7042, 8378, 6159, 9125, 6162, 6161, 6281, 8729, 7289, 7288, 9175, 9176,
      6883, 6884, 8446, 8447, 8478, 7412, 7411, 7011, 7012, 7398, 8544, 7821,
    ],
  },
  {
    id: "1160",
    name: "3º ANO/EF 2024 (MANHÃ)",
    users: [
      5126, 4694, 6609, 5064, 5272, 6309, 5907, 6280, 5566, 5730, 4709, 6158,
      6565, 5295, 6384, 5115, 5191, 5434, 5131, 5834, 6562, 6615, 6480, 7014,
      7015, 6358, 6359, 9043, 9042, 6920, 6921, 7226, 7225, 8601, 8602, 7303,
      7302, 8570, 8571, 7633, 7634, 7866, 7865, 6378, 6379, 8431, 8430, 8979,
      8980, 6813, 6814, 8725, 8728, 7005, 7004, 7113, 7114, 6190, 6192, 7019,
      7020, 7986, 7987, 8977, 8976, 9054, 8866,
    ],
  },
  {
    id: "1161",
    name: "3º ANO/EF 2024 (TARDE)",
    users: [
      5410, 5640, 5242, 6195, 5910, 7418, 7419, 7733, 7734, 7180, 7181, 8248,
      7508, 7509,
    ],
  },
  {
    id: "1171",
    name: "3º SÉRIE/EM 2024 (MANHÃ)",
    users: [
      5483, 5022, 5653, 4597, 5796, 4693, 5838, 6662, 4612, 5821, 5049, 5212,
      6557, 6673, 7522, 7523, 6850, 6851, 7756, 8880, 6228, 6229, 7945, 6354,
      6353, 7993, 9122, 6248, 6247, 6249, 7972, 7973, 6898, 6897, 7140, 7139,
      8967, 9150,
    ],
  },
  {
    id: "1162",
    name: "4º ANO/EF 2024 (MANHÃ)",
    users: [
      6065, 5713, 6591, 5368, 6342, 4724, 6506, 5932, 5386, 6058, 6172, 6146,
      4992, 6147, 4600, 5846, 4996, 4719, 6043, 6394, 4981, 5651, 6973, 6972,
      7837, 7838, 9016, 9015, 7364, 7365, 8651, 8730, 6398, 6399, 8905, 8120,
      8121, 7474, 7475, 8292, 8290, 8448, 8412, 6809, 6811, 8414, 8413, 6233,
      6234, 8005, 8006, 6815, 6816, 6393, 8264, 8744, 8745, 6790, 6791, 7753,
      7754,
    ],
  },
  {
    id: "1163",
    name: "4º ANO/EF 2024 (TARDE)",
    users: [
      5317, 5871, 5160, 5656, 6598, 6160, 5660, 6619, 7294, 7293, 8043, 7854,
      7065, 7066, 7758, 7757, 9027, 7328, 8433, 7762, 7763, 9058, 9059,
    ],
  },
  {
    id: "1164",
    name: "5º ANO/EF 2024 (MANHÃ)",
    users: [
      5933, 4615, 5043, 6639, 5026, 4695, 5411, 5132, 6617, 4559, 5958, 4752,
      6679, 4616, 4766, 5119, 6113, 4986, 4557, 5303, 6536, 4687, 8122, 6252,
      6251, 6883, 6884, 9083, 6857, 6856, 9145, 6359, 7420, 7421, 7021, 7022,
      9054, 6159, 9125, 6162, 6161, 8147, 9174, 6443, 6442, 9114, 9163, 6253,
      6254, 6467, 6472, 6469, 7008, 8365, 8366, 6800, 6799, 6153, 6154, 7268,
      8942, 8943, 0,
    ],
  },
  {
    id: "1165",
    name: "5º ANO/EF 2024 (TARDE)",
    users: [6161, 6603, 6208, 6167, 8435, 8434, 9036, 8490, 8444],
  },
  {
    id: "1166",
    name: "6º ANO/EF 2024 (MANHÃ)",
    users: [
      6386, 6299, 5159, 5664, 4797, 5222, 5654, 4870, 6245, 5919, 4822, 5786,
      5121, 5142, 6255, 4598, 6549, 5689, 5183, 6164, 4690, 5062, 5835, 5065,
      8733, 8732, 8588, 8587, 7064, 7063, 7770, 7771, 6521, 7151, 7150, 7756,
      8880, 4762, 4465, 8530, 8101, 8102, 7080, 9132, 7934, 7008, 7036, 7035,
      8543, 6228, 6229, 8446, 8447, 7803, 7802, 7100, 7101, 8438, 6354, 6353,
      6915, 6914, 7989, 7988, 6922, 6923,
    ],
  },
  {
    id: "1167",
    name: "7º ANO/EF 2024 (MANHÃ)",
    users: [
      4821, 6496, 5163, 5072, 6498, 5579, 6474, 4947, 6219, 5827, 6457, 4892,
      6273, 5665, 6686, 4614, 5407, 4717, 6424, 5030, 6117, 6258, 5559, 5634,
      7080, 9132, 8892, 8891, 7070, 7071, 6937, 6934, 8894, 8893, 7659, 8854,
      8856, 6743, 7120, 6744, 8502, 8503, 7980, 8833, 6659, 6660, 8558, 8559,
      7770, 7771, 9175, 9176, 6252, 6251, 7412, 7411, 6394, 6393, 8787, 6863,
      6862, 8058, 8057, 8546, 7633, 7634, 7727, 7728,
    ],
  },
  {
    id: "1168",
    name: "8º ANO/EF 2024 (MANHÃ)",
    users: [
      4556, 4703, 5118, 5639, 4572, 4849, 4873, 6395, 6226, 4639, 6454, 5094,
      4867, 5635, 6153, 6154, 6368, 6367, 7008, 7733, 7734, 6190, 6193, 6192,
      6195, 6194, 6191, 6596, 6595, 4762, 4465, 8744, 8745, 2531, 6281, 8729,
      8833, 6971, 6970, 6626, 6625, 7727, 7728,
    ],
  },
  {
    id: "1189",
    name: "9º ANO/EF 2024 (MANHÃ)",
    users: [
      4895, 4558, 5133, 5822, 4980, 4910, 6580, 6326, 6642, 6203, 4975, 5918,
      6047, 7073, 9158, 6155, 6158, 6157, 6156, 7023, 7024, 7972, 7973, 6789,
      6684, 6685, 6686, 9001, 9002, 8444, 9087, 9086, 8898, 8484, 6778, 6779,
      8101, 8102, 8271, 8270,
    ],
  },
  {
    id: "1172",
    name: "INFANTIL I 2024 (MANHÃ)",
    users: [
      4895, 4558, 5133, 5822, 4980, 4910, 6580, 6326, 6642, 6203, 4975, 5918,
      6047, 7073, 9158, 6155, 6158, 6157, 6156, 7023, 7024, 7972, 7973, 6789,
      6684, 6685, 6686, 9001, 9002, 8444, 9087, 9086, 8898, 8484, 6778, 6779,
      8101, 8102, 8271, 8270,
    ],
  },
  {
    id: "1173",
    name: "INFANTIL I 2024 (TARDE)",
    users: [
      6478, 6665, 6718, 6629, 6680, 6674, 6430, 8861, 8862, 9133, 9220, 9070,
      9165, 9166, 9151, 9152, 8795, 8796,
    ],
  },
  {
    id: "1198",
    name: "INFANTIL II - A 2024 (MANHÃ))",
    users: [
      6134, 6378, 6084, 6329, 6314, 6333, 6366, 6288, 6517, 6357, 6637, 6412,
      6030, 6419, 7916, 7917, 8715, 8714, 7226, 7225, 8636, 8637, 8609, 8736,
      8643, 8644, 7420, 7421, 8842, 8577, 8116, 8115, 8669, 9141, 8443, 8774,
      8773, 8248, 8780, 8779,
    ],
  },
  {
    id: "1174",
    name: "INFANTIL II - B - 2024 (MANHÃ)",
    users: [
      6477, 6461, 6499, 6406, 6510, 6413, 6486, 6504, 6432, 6654, 8859, 8860,
      8837, 8334, 8333, 7910, 7911, 8912, 8911, 7740, 7739, 8874, 8875, 9124,
      8902, 6816, 9105,
    ],
  },
  {
    id: "1175",
    name: "INFANTIL II 2024 (TARDE)",
    users: [
      6210, 6620, 6017, 6027, 6697, 6207, 6330, 6343, 6538, 6601, 6602, 6334,
      8493, 9060, 8229, 8228, 8245, 8244, 9193, 9194, 9164, 8489, 6868, 6869,
      8646, 8946, 9033, 9032, 9034, 9035, 8645,
    ],
  },
  {
    id: "1176",
    name: "INFANTIL III - A 2024 (MANHÃ)",
    users: [
      6139, 6344, 6254, 6089, 6128, 5991, 6181, 6166, 6224, 6028, 6024, 6196,
      6131, 5896, 6121, 6418, 6579, 6505, 5858, 8405, 8404, 8652, 6378, 6379,
      8334, 8333, 8389, 8390, 8189, 8190, 8459, 8460, 8440, 8443, 7274, 7275,
      8246, 8247, 8241, 8370, 7845, 7846, 8394, 8395, 7128, 7129, 8377, 7988,
      9000, 8903, 8904, 8025, 8026,
    ],
  },
  {
    id: "1178",
    name: "INFANTIL III - B 2024 (MANHÃ)",
    users: [
      6636, 6587, 6044, 6464, 6649, 6622, 6278, 6341, 6277, 6085, 6560, 6646,
      6699, 6186, 6270, 6576, 6472, 6358, 6099, 9078, 9079, 9010, 8264, 8841,
      8840, 9098, 9097, 9062, 9061, 8567, 8566, 8651, 8730, 8330, 8329, 8972,
      8973, 9093, 9092, 9197, 9198, 8466, 8465, 7120, 6744, 8995, 7303, 7302,
      8670, 8345, 8344,
    ],
  },
  {
    id: "1177",
    name: "INFANTIL III - C 2024 (TARDE)",
    users: [
      6256, 6661, 6095, 6248, 6563, 6467, 6177, 6390, 6469, 6468, 6179, 6648,
      6222, 6301, 6149, 5857, 7398, 8544, 9117, 8342, 8341, 8535, 9131, 8845,
      7065, 7066, 8379, 8380, 8848, 8847, 8846, 8457, 8458, 9095, 9094, 8770,
      8507, 8005, 8006, 8416, 8415, 8024, 8023,
    ],
  },
  {
    id: "1179",
    name: "INFANTIL IV - A 2024 (MANHÃ)",
    users: [
      5984, 5957, 5874, 5705, 5767, 5929, 5787, 5736, 5770, 5762, 6154, 5763,
      5764, 5869, 5751, 5818, 8181, 8146, 8044, 8047, 7824, 7825, 7910, 7911,
      8116, 8115, 7935, 8769, 6809, 6811, 7916, 7917, 7197, 7198, 8422, 8423,
      6915, 8041, 7837, 7838, 7971,
    ],
  },
  {
    id: "1180",
    name: "INFANTIL IV - B 2024 (MANHÃ)",
    users: [
      6053, 6331, 6119, 6068, 6220, 6417, 6689, 6115, 5757, 6487, 6573, 6059,
      6257, 6228, 6235, 6051, 6136, 6623, 5895, 8282, 8283, 8641, 8640, 8375,
      8374, 8305, 8304, 8502, 8503, 8777, 9181, 8369, 8368, 7899, 8877, 8876,
      8992, 8296, 8293, 8295, 8294, 8545, 2531, 8120, 8121, 8277, 8278, 8401,
      8400, 9063, 6304, 8078, 8077,
    ],
  },
  {
    id: "1181",
    name: "INFANTIL IV 2024 (TARDE)",
    users: [
      5759, 6230, 6133, 5905, 5996, 6322, 6174, 5915, 6381, 5947, 6704, 5781,
      6020, 7902, 7245, 8397, 8396, 6944, 8200, 8199, 8624, 8625, 8451, 6603,
      8719, 8720, 7418, 7419, 9200, 9199, 7929, 7928, 7065, 7066,
    ],
  },
  {
    id: "1182",
    name: "INFANTIL V - A 2024 (MANHÃ)",
    users: [
      5622, 5813, 6052, 5518, 5289, 6057, 6421, 5685, 6420, 5859, 5681, 6364,
      5436, 6354, 6310, 5828, 5701, 6393, 7715, 7716, 7967, 7966, 8279, 8280,
      7197, 7198, 7151, 7150, 8292, 8291, 8783, 8784, 7795, 7796, 8781, 8782, 0,
      7790, 7789, 8684, 8683, 7453, 7454, 8666, 8665, 8603, 7981, 8836, 7821,
      7820, 8744, 8745,
    ],
  },
  {
    id: "1183",
    name: "INFANTIL V - B 2024 (MANHÃ)",
    users: [
      5479, 5748, 5875, 5826, 5819, 5780, 6701, 5710, 5845, 6118, 5849, 5531,
      5820, 6132, 6641, 5749, 5669, 5307, 7514, 7888, 7887, 6857, 6856, 7980,
      6908, 6909, 6832, 6831, 9197, 9198, 7833, 7832, 8004, 8003, 8372, 8373,
      8010, 8009, 7032, 7033, 8394, 8395, 9085, 9084, 7773, 7774, 7274, 7275,
    ],
  },
  {
    id: "1184",
    name: "INFANTIL V - C 2024 (TARDE)",
    users: [
      5652, 6608, 5844, 5438, 5714, 6206, 5959, 5319, 5856, 6706, 5760, 6046,
      5847, 5909, 5655, 6090, 7755, 7328, 7327, 9134, 8002, 7457, 7458, 7840,
      7839, 8489, 8147, 7298, 7297, 8024, 8023, 9202, 9201, 7903, 7904, 8269,
      8005, 8006, 7508, 7509, 7756, 7861, 8335,
    ],
  },
];

export const getOneSignalUsers = (unit) => {
  const oneSignalUsers = unit === "CAMPINA GRANDE" ? campinaUsers : patosUsers;
  return oneSignalUsers;
};
